<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent :id="model?.system?.id" :component="!model?.system?.workflow">
      <div class="container-fluid">
        <div class="people-search-block">
          <div class="container p-0">
            <div class="row">
              <div class="col-12 col-xl-6">
                <div class="search-form">
                  <div class="search-form-head">
                    <h2 class="people-search-block__title">{{ model?.elements.title.value }}</h2>
                    <p class="mt-0">{{ model?.elements.subtitle.value }}</p>
                  </div>
                  <div class="search-form-body">
                    <div class="mb-3">
                      <div class="search-input-container">
                        <IconMagnifyingGlass />
                        <input
                          v-model="input.name"
                          class="form-control"
                          type="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>

                    <div v-if="locationFilterSource" class="dropdown mb-3">
                      <div
                        id="dropdownMenuButtonLocation"
                        class="form-select"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ locationTextSelected || locationFilterSource.name }}
                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonLocation">
                        <li>
                          <a
                            class="dropdown-item text-manatt-gray"
                            href="#"
                            @click.prevent="selectLocation(null)"
                          >
                            {{ locationFilterSource.name }}
                          </a>
                        </li>
                        <li v-for="(item, index) in locationFilterSource.data" :key="index">
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="selectLocation(item.key)"
                          >
                            {{ item.value }}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div v-if="capabilityFilterSource" class="dropdown mb-3">
                      <div
                        id="dropdownMenuButtonCapability"
                        class="form-select"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ capabilitySelected || capabilityFilterSource.name }}
                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonCapability">
                        <li>
                          <a
                            class="dropdown-item text-manatt-gray"
                            href="#"
                            @click.prevent="selectCapability(null)"
                          >
                            {{ capabilityFilterSource.name }}
                          </a>
                        </li>
                        <li v-for="(item, index) in capabilityFilterSource.data" :key="index">
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="selectCapability(item.key)"
                          >
                            {{ item.value }}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div v-if="positionFilterSource" class="dropdown mb-3">
                      <div
                        id="dropdownMenuButtonPosition"
                        class="form-select"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {{ positionSelected || positionFilterSource.name }}
                      </div>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonPosition">
                        <li>
                          <a
                            class="dropdown-item text-manatt-gray"
                            href="#"
                            @click.prevent="selectPosition(null)"
                          >
                            {{ positionFilterSource.name }}
                          </a>
                        </li>
                        <li v-for="(item, index) in positionFilterSource.data" :key="index">
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="selectPosition(item.key)"
                          >
                            {{ item.value }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="search-form-buttons">
                    <div>
                      <NuxtLink :to="peopleUrlList" class="btn btn-light ps-0">
                        {{ model?.elements.viewAllText.value }}
                      </NuxtLink>
                    </div>
                    <div>
                      <button class="btn btn-primary" @click="search()">
                        {{ model?.elements.searchButtonText.value }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <div class="image-container d-none d-xl-block">
                  <LqipImage
                    v-if="(model?.elements?.image?.value[0]?.url ?? '').length > 0"
                    :src="model?.elements?.image?.value[0]?.url"
                    height="582"
                    width="560"
                  />
                </div>
                <div class="image-container d-xl-none">
                  <LqipImage
                    v-if="(model?.elements?.mobileImage?.value[0]?.url ?? '').length > 0"
                    :src="model?.elements?.mobileImage?.value[0]?.url"
                    height="582"
                    width="560"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import type { PeopleSearchBlockModel } from '~/models'
import type { PeopleSearchBlockDataSet } from '~/types/data/PeopleSearchBlockDataSet'

defineProps<{ model: PeopleSearchBlockModel }>()
const input = reactive({
  filters: ['', '', ''], // Initialize with empty strings
  name: ''
})

const locationFilterSource = ref<PeopleSearchBlockDataSet>()
const locationSelected = ref<string | null>(null)
const locationTextSelected = ref<string | null>(null)
const selectLocation = (val: string | null) => {
  locationSelected.value = val
  locationTextSelected.value =
    locationFilterSource?.value?.data.find((x) => x.key == val)?.value ?? ''
}

const capabilityFilterSource = ref<PeopleSearchBlockDataSet>()
const capabilitySelected = ref<string | null>(null)
const selectCapability = (val: string | null) => {
  capabilitySelected.value = val
}

const positionFilterSource = ref<PeopleSearchBlockDataSet>()
const positionSelected = ref<string | null>(null)
const selectPosition = (val: string | null) => {
  positionSelected.value = val
}

async function getFilterSource() {
  // Fetch location data
  const { data } = await useFetch('/api/data/peopleSearchBlockDataSet')
  if (data && (data?.value?.length ?? 0 > 0)) {
    data.value?.forEach((v) => {
      locationFilterSource.value = v
    })
  }
  // Fetch taxonomy data
  const response = await fetchTaxonomy()
  getCapabilitiesSource(response)
  getPositionSource(response)
}

function getPositionSource(response: any) {
  if (response.data?.length ?? 0 > 0) {
    const taxonomy = response.data?.find((x: any) => x.system.codename == 'title')
    if (taxonomy) {
      positionFilterSource.value = {
        name: 'Position',
        data: taxonomy.terms.map((t: any) => {
          return { key: t.name, value: t.name }
        })
      }
    }
  }
}

function getCapabilitiesSource(response: any) {
  if (response.data?.length ?? 0 > 0) {
    const taxonomy = response.data?.find((x: any) => x.system.codename == 'capability')
    if (taxonomy) {
      capabilityFilterSource.value = {
        name: 'Capability',
        data: taxonomy.terms.map((t: any) => {
          return { key: t.name, value: t.name }
        })
      }
    }
  }
}

// Wait for the filter sources to be fetched before rendering
await getFilterSource()

async function search() {
  await navigateTo({
    path: peopleUrlList,
    query: {
      l: locationSelected.value || '',
      c: capabilitySelected.value || '',
      p: positionSelected.value || '',
      q: input.name || ''
    }
  })
}
</script>
